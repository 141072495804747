import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import axios from "axios";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Modal from "../components/Modal";
import Cta from "../components/cta";
import Face from "../images/face.svg";
import Enable from "../images/enable.svg";
import Chart from "../images/chart.svg";
import Likert from "../images/likert.svg";
import Check from "../images/check.svg";
import Circle from "../images/circle.svg";
import Automation from "../images/automation.svg";
import People from "../images/people.svg";
import Ai from "../images/ai.svg";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const IndexPage = () => {
  const [showModal, setShowModal] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState();

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const token = await executeRecaptcha("salesForm");
    const form = e.target;
    const data = new FormData(form);
    data.append("g-recaptcha-response", token);
    data.append("source", "sales-form");
    axios({
      method: "post",
      url: process.env.GATSBY_CONTACT_URL,
      headers: { "Content-Type": "multipart/form-data" },
      data,
    })
      .then((r) => {
        setProcessing(false);
        if (typeof window !== "undefined") {
          window.location = "/success/";
        }
      })
      .catch((r) => {
        setError("An error occurred, please try again later");
        setProcessing(false);
      });
  };

    const handleOpenModal = () => {
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
      setShowModal(false);
    };

  return (
    <Layout>
      <Seo
        title="Performance Management Software"
        description="Enabli is a SaaS platform that utilises Automation & Artificial Intelligence to ensure you are getting the best out of your sales organisation."
      />
      <div className="container">
        <div className="row">
          <div className="hero-home">
            <div className="hero-home-text">
              <h1
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-delay="300"
                data-sal-duration="400"
              >
                The Future of Management for Sales Organisations
              </h1>
              <p
                className="lead"
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-delay="400"
                data-sal-duration="400"
              >
                Enabli is a SaaS platform that utilises Automation & Artificial Intelligence to ensure you are getting the best out of your sales organisation.
              </p>
              <div className="btn-row"
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-delay="500"
                data-sal-duration="400"
              >
                <Link
                  to="/contact"
                  className="btn btn-lg btn-blue"
                >
                  Request a demo
                </Link>
                <button 
                  className="btn btn-lg btn-line"
                  onClick={handleOpenModal}
                >
                  How it works
                </button>
              </div>

              <Modal show={showModal} onClose={handleCloseModal}>
                <div className="video-wrapper">
                  <iframe className="youtube-embed" src="https://www.youtube.com/embed/O7w9W3iX1Hc?si=ocMAa7sUSDILIUoK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </Modal>
            </div>
            <div className="hero-home-img-wrapper">
              <StaticImage
                src="../images/hr-manager.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                className="hero-home-img"
                backgroundcolor="white"
                layout="fullWidth"
                quality="100%"
                alt="HR Manager at a laptop"
              />
              <div className="face">
                <img
                  src={Face}
                  alt="icon from a likert scale with a smiley face"
                />
              </div>
              <div className="enable">
                <img
                  src={Enable}
                  alt="icon from an enablement suggestion question"
                />
              </div>
              <div className="likert">
                <img src={Likert} alt="icon from a likert scale HR question" />
              </div>
              <div className="chart">
                <img
                  src={Chart}
                  alt="chart showing KPI Management performance"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section-pad-bottom" id="features">
        <div className="container-lg">
          <div className="row">
            <div className="feature-bg blue">
              <div className="container">
                <div className="row">
                  <div className="feature">
                    <div className="feature-img-wrapper">
                      <StaticImage
                        src="../images/enabli-charts.png"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        placeholder="none"
                        backgroundcolor="white"
                        className="feature-img"
                        layout="fullWidth"
                        quality="100%"
                        alt="laptop with the Enabli team performance charts dashboard"
                      />
                      <div className="circle">
                        <img src={Circle} alt="circle for decoration" />
                      </div>
                    </div>
                    <div className="feature-text">
                      <h2
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="300"
                        data-sal-duration="400"
                      >
                        Enabli Management Platform
                      </h2>
                      <p
                        className="lead"
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="400"
                        data-sal-duration="400"
                      >
                        Our out the box SaaS platform puts a standardised structure in place for your management team ensuring accountability and gathering data to help make better informed decisions.
                      </p>
                      <ul
                        className="list-unstyled feature-list"
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="500"
                        data-sal-duration="400"
                      >
                        <li>
                          <img src={Check} alt="check icon" />
                          Give structure to your management team
                        </li>
                        <li>
                          <img src={Check} alt="check icon" />
                          Access performance data
                        </li>
                        <li>
                          <img src={Check} alt="check icon" />
                          Retain staff IP
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-lg">
          <div className="row">
            <div className="feature-bg">
              <div className="container">
                <div className="row">
                  <div className="feature feature-mid">
                    <div className="feature-text">
                      <h3
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="300"
                        data-sal-duration="400"
                      >
                        Let's talk about Automation
                      </h3>
                      <p
                        className="lead"
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="400"
                        data-sal-duration="400"
                      >
                        Build customised automation into your current process, ensuring your managers are spending time on what's important.
                      </p>
                      <ul
                        className="list-unstyled feature-list"
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="500"
                        data-sal-duration="400"
                      >
                        <li>
                          <img src={Check} alt="check icon" />
                          Streamline time consuming processes
                        </li>
                        <li>
                          <img src={Check} alt="check icon" />
                          Remove friction from negative conversations
                        </li>
                        <li>
                          <img src={Check} alt="check icon" />
                          Utilise data instead of opinion
                        </li>
                      </ul>
                    </div>
                    <div className="feature-img-wrapper is-circle">
                      <img src={Automation} alt="automation icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-pad">
        <div className="container-lg">
          <div className="row">
            <div className="feature-bg purple">
              <div className="container">
                <div className="row">
                  <div className="feature">
                    <div className="feature-img-wrapper is-circle">
                      <img src={Ai} alt="automation icon" />
                    </div>
                    <div className="feature-text">
                      <h3
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="300"
                        data-sal-duration="400"
                      >
                        Artificial-Intelligence Consulting
                      </h3>
                      <p
                        className="lead"
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="400"
                        data-sal-duration="400"
                      >
                        Use our EnabliAI model to boost your team's performance.
                      </p>
                      <ul
                        className="list-unstyled feature-list"
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="500"
                        data-sal-duration="400"
                      >
                        <li>
                          <img src={Check} alt="check icon" />
                          Use our AI model to understand best practice for management specific to your company
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-pad-bottom">
        <div className="container-lg">
          <div className="row">
            <div className="feature-bg">
              <div className="container">
                <div className="row">
                  <div className="feature feature-mid">
                    <div className="feature-text">
                      <h3
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="300"
                        data-sal-duration="400"
                      >
                        Sales Advisory
                      </h3>
                      <p
                        className="lead"
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="400"
                        data-sal-duration="400"
                      >
                        Utilise our partner network to help manage your teams performance. If you're interested in joining our partner network <Link to="/contact">contact us</Link>.
                      </p>
                      <ul
                        className="list-unstyled feature-list"
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="500"
                        data-sal-duration="400"
                      >
                        <li>
                          <img src={Check} alt="check icon" />
                          Sales Training
                        </li>
                        <li>
                          <img src={Check} alt="check icon" />
                          Recruitment
                        </li>
                        <li>
                          <img src={Check} alt="check icon" />
                          Restructuring
                        </li>
                      </ul>
                    </div>
                    <div className="feature-img-wrapper is-circle">
                      <img src={People} alt="Person icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="pricing" className="section-pad-bottom">
        <div className="container">
          <div className="row">
            <div className="pricing-row">
              <div className="pricing-tier">
                <div className="pricing-tier-grid">
                  <div className="pricing-tier-content">
                    <h4 className="price">Pricing</h4>
                    <p>
                      <strong>Tailored solution, transparent pricing:</strong>
                    </p>
                    <p>
                      Let's create a proposal that perfectly fits your team's
                      needs. Enabli's pricing depends on your specific setup, so
                      a quick chat will help us give you an accurate quote. Get
                      started by filling out the short form on the right!
                    </p>
                    <p>
                      <strong>Have questions?</strong>
                      <br /> Find out more on our <Link to="/faqs">
                        FAQs
                      </Link>{" "}
                      page or <Link to="/contact">contact us</Link>.
                    </p>
                  </div>
                  <form
                    className="pricing-tier-form"
                    method="post"
                    name="sales"
                    id="sales"
                    onSubmit={handleOnSubmit}
                  >
                    <div className="form-row">
                      <div className="form-item w-100">
                        <label className="label" htmlFor="usersname">
                          How big is your team?
                        </label>
                        <div className="radio-toolbar">
                          <input type="radio" id="option1" name="teamSize" value="1-50" />
                          <label for="option1">1 - 50</label>

                          <input type="radio" id="option2" name="teamSize" value="51-200" />
                          <label for="option2">51 - 200</label>

                          <input type="radio" id="option3" name="teamSize" value="200+" />
                          <label for="option3">201 +</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-item w-100">
                        <label className="label" htmlFor="usersemail">
                          Email
                        </label>
                        <input
                          type="email"
                          className="input"
                          placeholder="Enter your email"
                          name="usersemail"
                          id="usersemail"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-item w-100">
                      <button
                        className="btn btn-lg btn-blue btn-block"
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="300"
                        data-sal-duration="400"
                      >
                        Contact Sales
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Cta />
    </Layout>
  );
};

const IndexPageWithRecaptcha = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcnUFciAAAAAPV3DD2sLA8YovKBy2EW8JX0Ksrx">
      <IndexPage />
    </GoogleReCaptchaProvider>
  );
};

export default IndexPageWithRecaptcha;
