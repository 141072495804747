import React from "react";
import "../styles/modal.css";
import Close from "../images/delete.svg";

const Modal = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
            <img src={Close} alt="close icon" />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;